+function ($) {
    $(document).ready(function(){
        console.log( 'start import Excel/csv' );

        $( '#pc2gps' ).click( function(){
            if( confirm('Haal GPS coordinaten op obv postcode') )
            {
                window.location = "/autobedrijf/pc2gps";
            }
        });

        $( '#fixgps' ).click( function(){
            if( confirm('vervang komma door punt in GPS coordinaten') )
            {
                window.location = "/autobedrijf/fixgps";
            }
        });

        $('#start_import').click(function(){

            $( this ).parent().addClass( 'loading' );

        });

        $('#verwijder_alles').click(function(){

            if( confirm('zeker weten!!!') )
            {
                if( confirm( 'Echt zeker weten!!!') )
                {
                    window.location = '/autobedrijf/allesweg';
                }
            }

        });

        $('form#fSelectToDelete').submit( function(e){
            if( !confirm('Zeker weten?') )return false;
            e.preventDefault(); //STOP default action

            var formURL = '/autobedrijf/deleteselected';
            var postData = $(this).serializeArray();
            var dfdDeleteselected = $.ajax(
                {
                    url : formURL,
                    type: "POST",
                    data : postData,
                    dataType : "JSON",
                }
            );
            dfdDeleteselected.done( function( data ){
                $.each( data.result, function( key, val ){
                    console.log('#row'+val);
                    $('#row'+val).remove();
                });
            });

        });

    });
}(jQuery);