function verwijder( uri, dom )
{
    console.log( 'verwijder via uri:' + uri );
    if( !confirm('Zeker weten!!') )return false;
    
    $.ajax({
        url: uri,
        method: 'POST',
        'data': { '_method':'DELETE' },
        dataType : "JSON",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success:function(data)
        {
            $( dom ).closest( 'tr' ).remove();
        },
        error: function(data)
        {
            console.log( data );
            alert( 'Er is iets mis gegaan' );
        }
    });
}

+function ($) {
    $(document).ready(function(){
        console.log( 'reftabellen' );
        $('button[id*="new_"]').click(function(){ 
            var arr = $( this ).attr( 'id' ).split( '_' );
            window.location = '/baseref/'+arr[1]+'/create';
        });

        $('#newautobedrijf').click(function(){
            window.location = '/autobedrijf/create';
        });
    });
}(jQuery);