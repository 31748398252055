function copySelectedRows( numcols )
{
    if( ! confirm( 'Kopieer alle geselecteerde regels.\nDoorgaan?') )return;

    var selectedIds = ',' + mygrid.getSelectedRowId() + ',';
    selectedIds = selectedIds.split( ',' );

    for( index in selectedIds )
    {
        if( selectedIds[ index ] > 0 )
        {
            console.log( 'id te kopieren:' + selectedIds[ index ] );

            var id=mygrid.uid();

            var values = '';
            for( col=1;col<numcols;col++ )
            {
                values += mygrid.cellById( selectedIds[ index ] , (col-1) ).getValue() + ',';
            }
            values += mygrid.cellById( selectedIds[ index ] , (numcols-1) ).getValue();

            console.log( 'values:' + values );

            mygrid.addRow(id, values , mygrid.getRowsNum() );
            mygrid.selectCell( mygrid.getRowsNum()-1, 0, false, false, true, true);
        }

    }

}

function deleteSelectedRows()
{
    if( ! confirm( 'Verwijder alle geselecteerde regels.\nDoorgaan?') )return;
    mygrid.deleteSelectedRows();
}